import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { auth } from '../config/firebase/firebase.config'
import LoadingPage from './LoadingPage'
import BmtLogo from '../assets/bmt.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    branding: {
        fontFamily: 'AvantGardeRegular'
    },
    logo: {
        width: '12rem'
    }
}))

export default function LoginPage() {

    const classes = useStyles()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [triedLoggingIn, setTriedLoggingIn] = useState(false)

    const isEmailVerified = useSelector(state => state.auth.isEmailVerified)
    const loginError = useSelector(state => state.auth.loginError)

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget

        if (name === 'userEmail') {
            setEmail(value)
        }
        else if (name === 'userPassword') {
            setPassword(value)
        }
    }

    const signInWithEmailAndPasswordHandler = (event) => {
        auth.signOut()
        event.preventDefault()
        setError('')
        setIsLoggingIn(true)
        setTriedLoggingIn(true)
        auth.signInWithEmailAndPassword(email, password)
            .then(response => {
                setIsLoggingIn(false)
            })
            .catch(error => {
                setIsLoggingIn(false)
                setError('Error signing in with password and email!')
                console.error('Error signing in with password and email', error)
            })
    }

    useEffect(() => {
        setError(loginError)
    }, [loginError])

    if (isLoggingIn) {
        return <LoadingPage />
    }

    return (
        <Container component='main' maxWidth='xs'>
            <Paper>
                <CssBaseline />
                <div className={classes.paper}>
                    <img className={classes.logo} src={BmtLogo} alt='' />
                    <Typography component='h1' variant='h5' className={classes.branding}>
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='userEmail'
                            label='Email Address'
                            name='userEmail'
                            autoComplete='email'
                            autoFocus
                            value={email}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='userPassword'
                            label='Password'
                            type='password'
                            id='userPassword'
                            autoComplete='current-password'
                            value={password}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        {!!error && <Typography variant='body1' color='error'>{error}</Typography>}
                        {
                            triedLoggingIn &&
                            !isEmailVerified &&
                            !error &&
                            loginError.length === 0 &&
                            <Typography variant='body1' color='error'>{'Please verify email to login.'}</Typography>}
                        {/* <FormControlLabel
                        control={<Checkbox value='remember' color='primary' />}
                        label='Remember me'
                    /> */}
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={(event) => signInWithEmailAndPasswordHandler(event)}
                        >
                            {'Sign In'}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <RouterLink to='/reset'>
                                    {'Forgot password?'}
                                </RouterLink>
                            </Grid>
                            {process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED === 'true' &&
                                <Grid item>
                                    <RouterLink to='/signup'>
                                        {"Don't have an account? Sign Up"}
                                    </RouterLink>
                                </Grid>}
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Container>
    )
}