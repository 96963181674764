import React from "react"
import ReactExport from "react-data-export"
import Button from '@material-ui/core/Button'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportExcel = props => {

    const { data, headers, excelFileName, roomMapping } = props

    return (
        <ExcelFile filename={excelFileName} element={<Button variant="outlined" startIcon={<CloudDownloadIcon />}>
            {'Download as Excel'}
        </Button>}>
            <ExcelSheet data={data} name="Extracted">
                {
                    headers.map((item, index) => {
                        // eslint-disable-next-line
                        if (['boundary', 'raw_text', 'confidence_score'].includes(item)) return
                        if (Object.keys(roomMapping).includes(item)) return <ExcelColumn key={index} label={roomMapping[item]} value={item} />
                        return <ExcelColumn key={index} label={item} value={item} />
                    })
                }
            </ExcelSheet>
        </ExcelFile >
    )
}

export default ExportExcel