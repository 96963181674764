import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import { PdfViewer } from "react-pdf-selection";
import get from "lodash.get";

const useStyles = makeStyles((theme) => ({
    pdfArea: {
        overflowY: "scroll",
        width: props => props.isAuditView ? "70%" : "60%",
    },
}));

const PdfArea = (props) => {
    const { pdf, selection, isAuditView } = props;

    const classes = useStyles({ isAuditView });

    const [highlight, setHighlight] = useState([]);
    const [scaleFactor, setScaleFactor] = useState(1);
    const [pageDimensions, setPageDimensions] = useState(new Map());
    const [maxDimensions, setMaxDimensions] = useState({
        width: window.innerWidth / 2 - 50,
        height: 500,
    });

    const scrollPdf = (selection) => {
        const selectionPageNo = get(selection, ["page"], -1)
        let scrollTill = 0
        const pageDimensionsArray = Array.from(pageDimensions.values())

        for (let i = 0; i < pageDimensionsArray.length; i++) {
            if (i === selectionPageNo - 1) break
            scrollTill += (pageDimensionsArray[i].height * (scaleFactor - 0.02) * window.innerWidth / maxDimensions.width)
        }
        scrollTill += (get(selection, ['top']) * get(pageDimensionsArray, [selectionPageNo - 1, 'height'], 1) * scaleFactor * window.innerWidth / maxDimensions.width / 100) - (window.innerHeight * 0.4)

        if (selectionPageNo !== -1) document.getElementById("pdf").scrollTo(0, scrollTill);
    }

    useEffect(() => {
        if (isAuditView) setScaleFactor(0.7)
        else setScaleFactor(0.6)
    }, [isAuditView])

    useEffect(() => {
        if (selection === null) {
            // setHighlight([]);
            return
        }
        scrollPdf(selection)
        setHighlight([
            {
                position: {
                    pageNumber: get(selection, ["page"]),
                    boundingRect: {
                        left: 0,
                        top: 0,
                        right: 100,
                        bottom: 100,
                    },
                    rects: [
                        {
                            left: get(selection, ["left"]),
                            top: get(selection, ["top"]),
                            right: get(selection, ["right"]),
                            bottom: get(selection, ["bottom"]),
                        },
                    ],
                },
            },
        ]);
        // eslint-disable-next-line
    }, [selection]);

    useEffect(() => {
        let maxHeight = 0,
            maxWidth = 0;
        // eslint-disable-next-line
        for (let [key, value] of pageDimensions) {
            if (maxHeight < value.height) {
                maxHeight = value.height;
            }
            if (maxWidth < value.width) {
                maxWidth = value.width;
            }
            setMaxDimensions({ width: maxWidth, height: maxHeight });
        }
    }, [pageDimensions]);

    return (
        <div id="pdf" className={classes.pdfArea}>

            <TransformWrapper
                maxScale={8}
                minScale={1}
                doubleClick={{
                    disabled: true
                }}
                pinch={{
                    disabled: true
                }}
                panning={{
                    activationKeys: ["Control"]
                }}
                wheel={{
                    step: 0.02,
                    activationKeys: ["Control"]
                }}>
                <TransformComponent>
                    <PdfViewer
                        scale={(scaleFactor - 0.02) * window.innerWidth / maxDimensions.width}
                        selections={highlight}
                        url={pdf}
                        onLoad={(dims) => setPageDimensions(dims)}
                    />
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default PdfArea;
