import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { auth } from '../config/firebase/firebase.config'
import LoadingPage from './LoadingPage'
import BmtLogo from '../assets/bmt.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    branding: {
        fontFamily: 'AvantGardeRegular'
    },
    successText: {
        color: 'green'
    },
    logo: {
        width: '12rem'
    }
}))

export default function LoginPage() {

    const classes = useStyles()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState(null)
    const [isSigningUp, setIsSigningUp] = useState(false)
    const [emailVerificationSent, setEmailVerificationSent] = useState(false)

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget

        if (name === 'userEmail') {
            setEmail(value)
        }
        else if (name === 'userPassword') {
            setPassword(value)
        }
        else if (name === 'userConfirmPassword') {
            setConfirmPassword(value)
        }
    }

    const createUserWithEmailAndPasswordHandler = async (event) => {
        setError('')
        setEmailVerificationSent(false)
        event.preventDefault()
        if (password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }
        setIsSigningUp(true)
        try {
            const response = await auth.createUserWithEmailAndPassword(email, password)
            response.user.sendEmailVerification()
            auth.signOut()
            setEmailVerificationSent(true)
            setIsSigningUp(false)
        }
        catch (error) {
            setIsSigningUp(false)
            setError('Error Signing up with email and password')
            return
        }

        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setError('')
    }

    if (isSigningUp) {
        return <LoadingPage />
    }

    return (
        <Container component='main' maxWidth='xs'>
            <Paper>
                <CssBaseline />
                <div className={classes.paper}>
                    <img className={classes.logo} src={BmtLogo} alt='' />
                    <Typography component='h1' variant='h5' className={classes.branding}>
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='userEmail'
                            label='Email Address'
                            name='userEmail'
                            autoComplete='email'
                            autoFocus
                            value={email}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='userPassword'
                            label='Password'
                            type='password'
                            id='userPassword'
                            autoComplete='current-password'
                            value={password}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='userConfirmPassword'
                            label='Confirm Password'
                            type='password'
                            id='userConfirmPassword'
                            autoComplete='current-password'
                            value={confirmPassword}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        {!!error && <Typography variant='body1' color='error'>{error}</Typography>}
                        {!!emailVerificationSent && <Typography variant='body1' className={classes.successText} color='primary'>{'Verification email sent. Please verify and login'}</Typography>}
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={(event) => createUserWithEmailAndPasswordHandler(event)}
                        >
                            {'Sign Up'}
                        </Button>
                        <Grid container>
                            <Grid item>
                                <RouterLink to='/login'>
                                    {'Already have an account? Sign In'}
                                </RouterLink>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Container>
    )
}