import React from "react"
// import { useSelector } from 'react-redux'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
// import Button from "@material-ui/core/Button"
import TableRow from "@material-ui/core/TableRow"
// import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
// import TextField from "@material-ui/core/TextField"
// import IconButton from "@material-ui/core/IconButton"
// import VisibilityIcon from "@material-ui/icons/Visibility"
// import get from "lodash.get"
// import axios from "axios"
// import moment from "moment"
import { useTable } from 'react-table'


// const EditableCell = ({
//     value: initialValue,
//     row: { index },
//     column: { id },
//     updateMyData, // This is a custom function that we supplied to our table instance
// }) => {
//     // We need to keep and update the state of the cell normally
//     const [value, setValue] = useState(initialValue)

//     const onChange = e => {
//         setValue(e.target.value)
//     }

//     // We'll only update the external data when the input is blurred
//     const onBlur = () => {
//         updateMyData(index, id, value)
//     }

//     // If the initialValue is changed external, sync it up with our state
//     useEffect(() => {
//         setValue(initialValue)
//     }, [initialValue])

//     return <TextField
//         variant="standard"
//         value={value}
//         onChange={onChange}
//         onBlur={onBlur} />
// }

const EditableTable = (props) => {


    const {
        tableData = [],
        tableHeaders = [],
        // highlightColumn,
        // setSelection,
        // onRowClick,
        selectedRowIndex,
        // setSelectedRowIndex,
        // hiddenColumns,
        // searchText,
        // pagination,
        // showUpload,
        // setIsUploadingGipsaFile,
        // getDocuments,
        // setOpenFailureSnackbar,
        // setOpenSuccessSnackbar,
        // roomMapping
    } = props


    // const classes = useStyles()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: tableHeaders,
        data: tableData,
    })

    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(10)

    // const role = useSelector(state => state.user.role)
    // const email = useSelector(state => state.user.userEmail)
    // const product = useSelector(state => state.product.product)

    // const clickRow = (index) => {
    //     const rowIndex = (page) * rowsPerPage + index
    //     if (!!onRowClick && ((role === 'ewa' && get(tableData, [rowIndex, "status"], '') !== 'pending') || role !== 'ewa')) {
    //         onRowClick(rowIndex)
    //     } else return
    // }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0)
    // }

    // const uploadGipsaFile2 = async (e, rowIndex) => {
    //     e.stopPropagation()
    //     const uploadRowIndex = (page) * rowsPerPage + rowIndex
    //     setIsUploadingGipsaFile(true)
    //     const fd = new FormData()
    //     fd.append("file", e.target.files[0])
    //     fd.append("email", email)
    //     fd.append("hospital_name", get(tableData, [uploadRowIndex, 'name']))
    //     fd.append("hospital_id", get(tableData, [uploadRowIndex, 'hospital_id']))
    //     let response
    //     try {
    //         response = await axios.post(`/${product}/uploadforhospital`, fd)
    //     } catch (error) {
    //         setIsUploadingGipsaFile(false)
    //         setOpenFailureSnackbar(true)
    //         return
    //     }
    //     if (response.status !== 200) {
    //         setIsUploadingGipsaFile(false)
    //         setOpenFailureSnackbar(true)
    //         return
    //     }
    //     await getDocuments()
    //     setIsUploadingGipsaFile(false)
    //     setOpenSuccessSnackbar(true)
    // }

    // const checkIfUploadedWithin6Hrs = (uploadTime) => {
    //     const cutoffTime = moment(uploadTime).add(6, 'h')
    //     return cutoffTime.isAfter(moment())
    // }

    // useEffect(() => {
    //     setPage(0)
    // }, [searchText])

    // useEffect(() => {
    //     if (tableHeaders.length > 0 && role === 'ewa' && showUpload && !tableHeaders.includes('Upload')) {
    //         tableHeaders.push('Upload')
    //     }
    //     // eslint-disable-next-line
    // }, [tableHeaders])

    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell style={{ fontWeight: "600", maxWidth: '400px' }} {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {/* <TableRow>
                            {tableHeaders.map((header, index) => {
                                if (hiddenColumns.includes(header)) return null
                                if (header === "boundary") {
                                    return (
                                        <TableCell
                                            style={{ fontWeight: "600" }}
                                            classes={{
                                                root: classes.tableCell
                                            }}
                                            key={index}
                                        >
                                            {highlightColumn}
                                        </TableCell>
                                    )
                                }
                                if (Object.keys(roomMapping).includes(header)) {
                                    return (
                                        <TableCell
                                            style={{ fontWeight: "600", maxWidth: '400px' }}
                                            key={index}
                                        >
                                            {roomMapping[header]}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell
                                        style={{ fontWeight: "600", maxWidth: '400px' }}
                                        key={index}
                                    >
                                        {header}
                                    </TableCell>
                                )
                            })}
                        </TableRow> */}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow
                                    style={{
                                        backgroundColor: i === selectedRowIndex ? '#e3fea6' : null
                                    }}
                                    selected={i === selectedRowIndex}
                                    {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                    })}
                                </TableRow>
                            )
                        })}
                        {/* {tableData
                            .slice(
                                pagination ? page * rowsPerPage : 0,
                                pagination ? page * rowsPerPage + rowsPerPage : tableData.length)
                            .map((row, rowIndex) => (
                                <TableRow
                                    hover={!!onRowClick && ((role === 'ewa' && get(row, ["status"], '') !== 'pending') || role !== 'ewa')}
                                    onClick={() => clickRow(rowIndex)}
                                    key={rowIndex}
                                    // classes={{
                                    //     selected: classes.selectedRow
                                    // }}
                                    style={{
                                        backgroundColor: rowIndex === selectedRowIndex ? '#e3fea6' : null
                                    }}
                                    selected={rowIndex === selectedRowIndex}
                                >
                                    {tableHeaders.map((header, index) => {
                                        if (hiddenColumns.includes(header)) return null
                                        if (header === "boundary") {
                                            return (
                                                <TableCell key={index}
                                                    classes={{
                                                        root: classes.tableCell
                                                    }}>
                                                    {Object.keys(
                                                        get(row, ["boundary"], {})
                                                    ).length > 0 && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedRowIndex(rowIndex)
                                                                    setSelection(
                                                                        get(
                                                                            row,
                                                                            ["boundary"],
                                                                            {}
                                                                        )
                                                                    )
                                                                }
                                                                }
                                                            >
                                                                <VisibilityIcon fontSize="inherit" />
                                                            </IconButton>
                                                        )}
                                                </TableCell>
                                            )
                                        } else if (header === "Upload") {
                                            return (
                                                <TableCell key={index}
                                                    classes={{
                                                        root: classes.tableCell
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        size="small"
                                                        style={{ zIndex: 10001 }}
                                                        disabled={get(
                                                            row,
                                                            ["status"],
                                                            ''
                                                        ) !== 'pending'
                                                            &&
                                                            !checkIfUploadedWithin6Hrs(get(
                                                                row,
                                                                ["upload_time"],
                                                                moment().subtract(1, 'y')
                                                            ))
                                                        }
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        Upload
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept='application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv'
                                                            onChange={(e) => uploadGipsaFile2(e, rowIndex)}
                                                        />
                                                    </Button>

                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell key={index}
                                                    classes={{
                                                        root: classes.tableCell
                                                    }}>
                                                    {get(row, [header], "")}
                                                </TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                            ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {pagination && <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />} */}
        </Paper>
    )
}

export default EditableTable
