import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import LoadingPage from './LoadingPage'
import * as AuthActions from '../store/actions/auth'
import * as ProductActions from '../store/actions/product'

const PreAuthConfigPage = props => {

    const dispatch = useDispatch();

    useEffect(() => {
        let productName = process.env.REACT_APP_PRODUCT
        document.title = process.env.REACT_APP_TAB_TITLE
        dispatch(ProductActions.setProduct(productName));
        dispatch(AuthActions.setIsConfigSet(true));
        // eslint-disable-next-line
    }, []);

    return <LoadingPage />
}

export default PreAuthConfigPage