import React from 'react'
import { makeStyles } from "@material-ui/styles"
import get from 'lodash.get'
import Box from '@material-ui/core/Box'
import EditableTable from '../general/table/EditableTable'
import IconButton from "@material-ui/core/IconButton"
import VisibilityIcon from "@material-ui/icons/Visibility"

const useStyles = makeStyles((theme) => ({
    tableNotFound: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        paddingTop: "1rem",
    }
}))

const SingleTable = props => {

    const classes = useStyles()

    const {
        tableDetails,
        selectedTabIndex,
        index,
        setSelection,
        setSelectedRowIndex,
        selectedRowIndex,
        roomMapping,
        auditConfig
    } = props

    const columns = React.useMemo(
        () => get(
            tableDetails,
            ["table_headers"],
            []
        ).filter(item =>
            ![...get(
                auditConfig,
                ['hidden_fields'],
                []
            ), 'confidence_score', 'raw_text'].includes(item)
        )
            .map(item => {
                if (Object.keys(roomMapping).includes(item)) {
                    return { Header: roomMapping[item], accessor: item }
                }
                if (item === 'boundary') {
                    return {
                        Header: get(
                            tableDetails,
                            ["highlight_col"],
                            'boundary'
                        ),
                        accessor: item,
                        Cell: row =>
                            Object.keys(
                                get(row, ["value"], {})
                            ).length > 0 ? (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setSelectedRowIndex(get(row, ['row', 'index']))
                                        setSelection(
                                            get(
                                                row,
                                                ["value"],
                                                {}
                                            )
                                        )
                                    }
                                    }
                                >
                                    <VisibilityIcon fontSize="inherit" />
                                </IconButton>
                            ) : null
                    }
                }
                return { Header: item, accessor: item }
            }),
        // eslint-disable-next-line
        []
    )


    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={0}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        )
    }

    const noOfRows = get(
        tableDetails,
        ["table_data"],
        []
    ).length
    return (
        <TabPanel
            value={selectedTabIndex}
            index={index}
            key={index}
        >
            {noOfRows > 0 ? (
                <EditableTable
                    tableData={get(
                        tableDetails,
                        ["table_data"],
                        []
                    )}
                    tableHeaders={columns}
                    // highlightColumn={get(
                    //     tableDetails,
                    //     ["highlight_col"],
                    //     []
                    // )}
                    // setSelection={setSelection}
                    selectedRowIndex={selectedRowIndex}
                // setSelectedRowIndex={setSelectedRowIndex}
                />
            ) : (
                <div className={classes.tableNotFound}>
                    Data not found
                </div>
            )}
        </TabPanel>
    )
}

export default SingleTable