export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'
export const AUTH_CHECKED = 'AUTH_CHECKED'
export const SET_IS_EMAIL_VERIFIED = 'SET_IS_EMAIL_VERIFIED'
export const SET_IS_CONFIG_SET = 'SET_IS_CONFIG_SET'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const VERIFY_EMAIL_AND_AUTH = 'VERIFY_EMAIL_AND_AUTH'

export const SET_PRODUCT = 'SET_PRODUCT'

export const SET_USER_DETAILS = 'SET_USER_DETAILS'