import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"
import IconButton from "@material-ui/core/IconButton"
import AppBar from "@material-ui/core/AppBar"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import VisibilityIcon from "@material-ui/icons/Visibility"
import get from "lodash.get"
import clonedeep from "lodash.clonedeep"

import ExportExcel from '../general/ExportExcel'
import { useSelector } from "react-redux"
import SingleTable from "./SingleTable"

const useStyles = makeStyles((theme) => ({
    jsonArea: {
        overflowY: "scroll",
        width: "40%",
        overflowX: "hidden",
    },
    loadingJsonArea: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
    },
    loadingIcon: {
        marginBottom: "1rem",
    },
    sectionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        marginLeft: "1rem",
        flex: 1,
    },
    tableTabsTitleContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem",
        alignItems: 'center',
        flex: 1,
    },
    tabsContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        flex: 1,
    },
    cardTitleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    cardTitle: {
        color: "#707070",
    },
    tableTitle: {
        color: "#707070",
        fontSize: '1.2rem'
    },
    cardValue: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "0.5rem",
    },
    tableNotFound: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        paddingTop: "1rem",
    },
    excelContainer: {
        marginLeft: 'auto',
        marginBottom: '0.5rem'
    },
}))

const Section = (props) => {
    const { keyVals, setSelection, auditConfig, roomMapping, excelFileName } = props

    const classes = useStyles()

    return (
        <div className={classes.sectionContainer}>
            {
                // eslint-disable-next-line
                Object.keys(keyVals).map((item, index) => {
                    if (keyVals[item]["data_type"] === "key_value") {
                        return (
                            <KeyValCard
                                key={index}
                                keyName={item}
                                val={keyVals[item]}
                                setSelection={setSelection}
                            />
                        )
                    } else if (keyVals[item]["data_type"] === "tables_tabs") {
                        return (
                            <TableTabs
                                key={index}
                                title={item}
                                details={keyVals[item]}
                                setSelection={setSelection}
                                auditConfig={auditConfig}
                                roomMapping={roomMapping}
                                excelFileName={excelFileName}
                            />
                        )
                    }
                })
            }
        </div>
    )
}

const KeyValCard = (props) => {
    const classes = useStyles()

    const { keyName, val, setSelection } = props

    return (
        <div className={classes.cardContainer}>
            <Typography
                variant="subtitle2"
                component="div"
                className={classes.cardTitle}
            >
                {keyName}
            </Typography>
            <div className={classes.cardTitleContainer}>
                <Typography
                    variant="subtitle1"
                    component="div"
                    className={classes.cardValue}
                >
                    {get(val, ["value"], "")}
                </Typography>
                {Object.keys(get(val, ["boundary"], {})).length > 0 && (
                    <IconButton
                        size="small"
                        onClick={() => setSelection(get(val, ["boundary"], {}))}
                    >
                        <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                )}
            </div>
        </div>
    )
}

const TableTabs = (props) => {
    const classes = useStyles()

    const { details, setSelection, title, auditConfig, roomMapping, excelFileName } = props

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [selectedRowIndex, setSelectedRowIndex] = useState()
    const [allData, setAllData] = useState([])
    const [headers, setHeaders] = useState([])

    const role = useSelector(state => state.user.role)

    useEffect(() => {
        setSelectedRowIndex(null)
        setSelection(null)
        const tempData = clonedeep(get(details, ["table_titles"], [])).map((item) => {
            if (get(details, [item, 'table_headers'], null) !== null) {
                const tempHeaders = clonedeep(get(details, [item, 'table_headers'], []))
                const boundaryIndex = tempHeaders.findIndex(item => item === 'boundary')
                if (boundaryIndex !== -1) {
                    tempHeaders.splice(boundaryIndex, 1)
                }
                const rawTextIndex = tempHeaders.findIndex(item => item === 'raw_text')
                if (rawTextIndex !== -1) {
                    tempHeaders.splice(rawTextIndex, 1)
                }
                const confScoreIndex = tempHeaders.findIndex(item => item === 'confidence_score')
                if (confScoreIndex !== -1) {
                    tempHeaders.splice(confScoreIndex, 1)
                }
                setHeaders(tempHeaders)
            }
            return clonedeep(get(details, [item, 'table_data'], []))
        })
        let finalData = [].concat.apply([], tempData)
        finalData.forEach((item) => {
            for (let o_key of Object.keys(item)) {
                if (item[o_key] == null)
                    item[o_key] = ''
            }
            delete item['boundary']
            delete item['raw_text']
            delete item['confidence_score']
        })
        setAllData(finalData)
        // eslint-disable-next-line
    }, [selectedTabIndex])

    return (
        <div>
            <div className={classes.tableTabsTitleContainer}>
                <Typography
                    variant="subtitle2"
                    component="div"
                    className={classes.tableTitle}
                >
                    {title}
                </Typography>
                <div className={classes.excelContainer}>
                    {role !== 'auditor' && <ExportExcel
                        roomMapping={roomMapping}
                        excelFileName={excelFileName}
                        data={allData}
                        headers={headers} />}
                </div>
            </div>
            <div className={classes.tabsContainer}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(e, newIndex) =>
                            setSelectedTabIndex(newIndex)
                        }
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {get(details, ["table_titles"], []).map(
                            (title, index) => {
                                return <Tab label={title} key={index} />
                            }
                        )}
                    </Tabs>
                </AppBar>
                {get(details, ["table_titles"], []).map((title, index) => (
                    <SingleTable
                        tableDetails={get(details, [title], {})}
                        selectedTabIndex={selectedTabIndex}
                        index={index}
                        roomMapping={roomMapping}
                        auditConfig={auditConfig}
                        selectedRowIndex={selectedRowIndex}
                        setSelectedRowIndex={setSelectedRowIndex}
                        setSelection={setSelection} />
                ))}
            </div>
        </div>
    )
}

const JsonArea = (props) => {
    const classes = useStyles()

    const { json, isFetchingPdf, setSelection, auditConfig, roomMapping, excelFileName } = props

    if (isFetchingPdf) {
        return (
            <Paper className={classes.loadingJsonArea}>
                <CircularProgress className={classes.loadingIcon} />
                <Typography variant="body2">Fetching Data</Typography>
            </Paper>
        )
    }

    return (
        <Paper className={classes.jsonArea}>
            {Object.values(json).map((item, index) => (
                <Section
                    keyVals={item}
                    key={index}
                    setSelection={setSelection}
                    auditConfig={auditConfig}
                    roomMapping={roomMapping}
                    excelFileName={excelFileName}
                />
            ))}
        </Paper>
    )
}

export default JsonArea
