import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


import { auth } from './config/firebase/firebase.config'
import RoutingPage from './RoutingPage'
import * as AuthActions from './store/actions/auth'

const App = (props) => {

  const dispatch = useDispatch()

  const isSignedIn = useSelector(state => state.auth.isSignedIn)
  const isAuthChecked = useSelector(state => state.auth.isAuthChecked)

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth === null) {
        dispatch(AuthActions.signout())
        dispatch(AuthActions.setAuthChecked())
      } else {
        if (!userAuth?.emailVerified && process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED === 'true') {
          dispatch(AuthActions.setIsEmailVerified(false))
          dispatch(AuthActions.setAuthChecked())
        } else {
          dispatch(AuthActions.signin(userAuth.email, userAuth.uid))
        }
      }
    })
    // eslint-disable-next-line
  }, [])

  return (
    <RoutingPage
      isSignedIn={isSignedIn}
      isAuthChecked={isAuthChecked} />
  );
}

export default App;