const initialState = {}

const errorReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        default:
            return state
    }
}

export default errorReducer