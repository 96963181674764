import { SET_USER_DETAILS } from "../type"

export const setUserDetails = (email, uid, role) => ({
    type: SET_USER_DETAILS,
    payload: {
        email,
        uid,
        role
    }
})
