import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from '@material-ui/lab/Autocomplete'
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import get from 'lodash.get';
import set from 'lodash.set';
import clonedeep from 'lodash.clonedeep';

import { isFloat } from '../../utils'

const useStyles = makeStyles((theme) => ({
    auditArea: {
        overflowY: "scroll",
        width: "30%",
        overflowX: "hidden",
    },
    loadingAuditArea: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
    },
    loadingIcon: {
        marginBottom: "1rem",
    },
    cardTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        margin: '0 1rem',
    },
    cardTitle: {
        color: '#707070',
        marginLeft: '1rem',
        marginTop: '0.5rem'
    },
    buttonContainer: {
        margin: '0 1rem',
    },
    addButton: {
        backgroundColor: '#21ba44',
        color: 'white',
    },
    removeButton: {
        backgroundColor: '#db2828',
        color: 'white',
    },
    button: {
        marginRight: '0.5rem',
        marginBottom: '0.5rem'
    },
    dropdownContainer: {
        margin: '1rem'
    },
    option: {
        color: 'black',
    },
    secondaryDropdownText: {
        fontSize: '0.75rem',
        color: 'grey'
    },
    nfnrBtns: {
        marginLeft: '0.5rem'
    },
    formContainer: {
        marginBottom: '0.5rem'
    }
}))

const AuditArea = (props) => {

    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()

    const {
        isFetchingData,
        setSelection,
        auditArray,
        setAuditArray,
        auditStartIndex,
        documentId,
        getDocumentDataForAudit,
        auditConfig,
        filename
    } = props;

    const [currentIndex, setCurrentIndex] = useState(auditStartIndex)
    const [currentToDisplay, setCurrentToDisplay] = useState([])
    const [currentToDisplayDataType, setCurrentToDisplayDataType] = useState(null)
    const [operationInProgress, setOperationProgress] = useState(false)
    const [updatedCurrentRow, setUpdatedCurrentRow] = useState(false)
    const [isAddEntryMode, setIsAddEntryMode] = useState(false)
    const [isAddingEndingRow, setIsAddingEndingRow] = useState(false)
    const [isPrevious, setIsPrevious] = useState(false)
    const [openValidationDialog, setOpenValidationDialog] = useState(false)
    const [validationError, setValidationError] = useState([])
    const [auditArrayCopy, setAuditArrayCopy] = useState([])
    const [showDocumentCompletionDialog, setShowDocumentCompletionDialog] = useState(false)
    const [highlightShiftCounter, setHighlightShiftCounter] = useState(0)
    const [issueComment, setIssueComment] = useState('')
    const [showIssueDialog, setShowIssueDialog] = useState(false)
    const [issueError, setIssueError] = useState(false)
    const [isRaisingIssue, setIsRaisingIssue] = useState(false)

    const isBlankAuditArray = useRef(true);

    const product = useSelector(state => state.product.product)
    const email = useSelector(state => state.user.userEmail)
    const role = useSelector(state => state.user.role)

    const updateValue = (index, newValue) => {
        setUpdatedCurrentRow(true)
        const tempCurrentToDisplay = clonedeep(currentToDisplay)
        set(tempCurrentToDisplay, [index, 'value'], newValue)
        setCurrentToDisplay(tempCurrentToDisplay)
    }

    const editEntry = async (operation, toDisplay, indexOffset = 0) => {
        const currentDataToSend = get(clonedeep(auditArray), [currentIndex])
        set(currentDataToSend, ['to_display'], toDisplay)
        if (isAddingEndingRow) {
            const rowId = get(currentDataToSend, ['row_id'], -99)
            set(currentDataToSend, ['row_id'], parseInt(rowId) + 1)
        }
        await axios.post(`/${product}/editentry`, {
            email,
            document_id: documentId,
            operation,
            audit_start_index: currentIndex + indexOffset,
            data: currentDataToSend,
            old_data: get(auditArrayCopy, [currentIndex], {}),
        })
        setIsAddingEndingRow(false)
    }

    const raiseIssue = async () => {
        if (issueComment.length === 0) {
            setIssueError(true)
            return
        }
        setIsRaisingIssue(true)
        await axios.post(`/${product}/raiseissue`, {
            email,
            document_id: documentId,
            status: role + '_issue',
            filename,
            comment: issueComment
        })
        setIsRaisingIssue(false)
        history.push('/', { data: location.state.data })
    }

    const markAuditCompleted = async () => {
        setOperationProgress(true)
        await axios.post(`/${product}/auditcomplete`, {
            document_id: documentId,
            email
        })
        history.push('/', { data: location.state.data })
    }

    const validateDataType = (dataType, value) => {
        if (dataType === 'numeric') {
            return isFloat(value)
        }
    }

    const validateEntry = () => {
        const validationConfig = get(auditConfig, ['field_validation'], [])
        const validationOutput = []
        for (const validationField of Object.keys(validationConfig)) {
            const fieldIndex = currentToDisplay.findIndex(item => item['key'] === validationField)
            const expectedDataType = get(validationConfig, [validationField, 'data_types'], null)
            const expectedValues = get(validationConfig, [validationField, 'values'], [])
            const regex = get(validationConfig, [validationField, 'regex'], '')
            if (fieldIndex !== -1) {
                const fieldValue = get(currentToDisplay, [fieldIndex, 'value'], null)
                if (!!fieldValue) {
                    if (!!expectedDataType) {
                        if (validateDataType(expectedDataType, fieldValue)) {
                            continue
                        }
                    }
                    if (expectedValues.length > 0) {
                        if (expectedValues.map(item => item.toLowerCase()).includes(fieldValue.toLowerCase())) {
                            continue
                        }
                    }
                    if (regex.length > 0) {
                        const re = new RegExp(regex)
                        if (re.test(fieldValue)) {
                            continue
                        }
                    }
                }
            } else {
                continue
            }
            validationOutput.push(`${validationField} can be only ${expectedDataType}, ${expectedValues.join(', ')}`)
        }
        return validationOutput
    }

    const verifyEntry = async () => {
        if (currentToDisplayDataType === 'tables_tabs') {
            const validationOutput = validateEntry()
            if (validationOutput.length > 0) {
                setValidationError(validationOutput)
                setOpenValidationDialog(true)
                return
            }
        }
        setOperationProgress(true)
        const tempAuditArray = clonedeep(auditArray)
        set(tempAuditArray, [currentIndex, 'to_display'], currentToDisplay)
        setAuditArray(tempAuditArray)
        await editEntry(
            updatedCurrentRow ? 'edit' : 'nochange',
            currentToDisplay,
            currentIndex < auditArray.length - 1 ? 1 : 0)
        if (currentIndex < auditArray.length - 1) {
            setCurrentIndex(prev => prev + 1)
        } else {
            setShowDocumentCompletionDialog(true)
        }
        setOperationProgress(false)
    }

    // const removeEntry = async () => {
    //     setOperationProgress(true)
    //     if (currentIndex < auditArray.length - 1) {
    //         await editEntry('delete', currentToDisplay)
    //     } else {
    //         await editEntry('delete', currentToDisplay, -1)
    //     }
    //     setOperationProgress(false)
    //     getDocumentDataForAudit()
    //     setIsAddEntryMode(false)
    // }

    const addEntry = (isAddingAtTheEnd = false) => {
        setIsAddingEndingRow(false)
        if (isAddingAtTheEnd) {
            setIsAddingEndingRow(true)
            setShowDocumentCompletionDialog(false)
        }
        setCurrentToDisplay(prev => {
            return prev.map((keyVal) => {
                return {
                    key: keyVal['key'],
                    value:
                        get(auditConfig, ['fields_to_copy_in_new_row'], []).includes(keyVal['key'])
                            ? keyVal['value']
                            : ''
                }
            })
        })
        setSelection({})
        setIsAddEntryMode(true)
    }

    const addEntryToDb = async () => {
        if (currentToDisplayDataType === 'tables_tabs') {
            const validationOutput = validateEntry()
            if (validationOutput.length > 0) {
                setValidationError(validationOutput)
                setOpenValidationDialog(true)
                return
            }
        }
        setOperationProgress(true)
        await editEntry('add', currentToDisplay, 1)
        setOperationProgress(false)
        getDocumentDataForAudit()
        setIsAddEntryMode(false)
    }

    const cancelAddingRow = () => {
        setIsAddEntryMode(false)
        changeIndex()
    }

    const changeIndex = () => {
        let tempCurrentToDisplay = get(clonedeep(auditArray), [currentIndex, 'to_display'], [])
        setCurrentToDisplay(tempCurrentToDisplay)
        setCurrentToDisplayDataType(get(clonedeep(auditArray), [currentIndex, 'data_type'], ''))
        setUpdatedCurrentRow(false)
        if (!!document.getElementById("audit-area")) {
            document.getElementById("audit-area").scrollTo(0, 0);
        }
        const boundaryIndex = tempCurrentToDisplay.findIndex(item => get(item, ['key']) === 'boundary')
        if (boundaryIndex === -1) {
            setSelection({})
        } else {
            setSelection({
                ...get(tempCurrentToDisplay, [boundaryIndex, 'value'], {}),
                overwrite: true,
                previous: isPrevious
            })
        }
        setIsPrevious(false)
        setHighlightShiftCounter(0)
    }

    const selectDropdownValue = (index, value) => {
        const tempCurrentToDisplay = clonedeep(currentToDisplay)
        set(tempCurrentToDisplay, [index, 'value'], value)
        setCurrentToDisplay(tempCurrentToDisplay)
        setUpdatedCurrentRow(true)
    }

    const handleDialogClose = (e, r) => {
        setShowDocumentCompletionDialog(false)
    }

    const goToPreviousLine = () => {
        setCurrentIndex(prev => prev - 1)
        setIsPrevious(true)
    }

    const currentToDisplayConfidenceIsHigh = () => {
        const confidenceIndex = currentToDisplay.findIndex(item => get(item, ['key'], '') === 'confidence_score')
        if (confidenceIndex > -1) {
            const confidenceScore = get(currentToDisplay, [confidenceIndex, 'value'], 0)
            if (confidenceScore === 1) return true
        }
        return false
    }

    const shiftHighlight = (toPrevious) => {
        const updatedToDisplay = get(auditArrayCopy, [toPrevious ? currentIndex + highlightShiftCounter - 1 : currentIndex + highlightShiftCounter + 1, 'to_display'], null)
        const updatedToDisplayDataType = get(auditArrayCopy, [toPrevious ? currentIndex + highlightShiftCounter - 1 : currentIndex + highlightShiftCounter + 1, 'data_type'], null)
        if (updatedToDisplayDataType !== 'tables_tabs') return
        setHighlightShiftCounter(prev => toPrevious ? prev - 1 : prev + 1)
        if (!!updatedToDisplay) {
            let tempCurrentToDisplay = clonedeep(currentToDisplay)
            let prevBoundary = {}
            // TODO HARDCODING - To Be Removed
            tempCurrentToDisplay.forEach((item, index) => {
                if (get(item, ['key']) === 'boundary' || get(item, ['key']).includes('room_type')) {
                    set(tempCurrentToDisplay, [index, 'value'], get(updatedToDisplay, [index, 'value']))
                }
                if (get(item, ['key']) === 'boundary') {
                    prevBoundary = get(updatedToDisplay, [index, 'value'])
                }
            })
            setSelection(prevBoundary)
            setCurrentToDisplay(tempCurrentToDisplay)
        }
    }

    useEffect(() => {
        setCurrentIndex(auditStartIndex)
    }, [auditStartIndex])

    useEffect(() => {
        changeIndex()
        // eslint-disable-next-line
    }, [currentIndex, auditArray])

    useEffect(() => {
        if (showIssueDialog) {
            setIssueComment('')
            setIssueError(false)
        }
        // eslint-disable-next-line
    }, [showIssueDialog])

    useEffect(() => {
        if (auditArray.length > 0 && isBlankAuditArray.current) {
            isBlankAuditArray.current = false
            setAuditArrayCopy(clonedeep(auditArray))
        }
        // eslint-disable-next-line
    }, [auditArray])

    const renderAuditForm = () => {
        if (currentToDisplayDataType === 'dropdown') {
            return (
                currentToDisplay.map((item, index) => {
                    const dropdownValue = get(item, ['value'], null)
                    return <div key={index} className={classes.dropdownContainer}>
                        <Autocomplete
                            options={get(item, ['dropdown_options'], [])}
                            getOptionLabel={(option) => option.primary}
                            onChange={(e, value, r) => selectDropdownValue(index, value)}
                            getOptionSelected={(option, value) => (
                                (get(option, ['primary']) === get(value, ['primary'])) &&
                                (get(option, ['secondary']) === get(value, ['secondary']))
                            )}
                            value={
                                !!dropdownValue &&
                                    Object.keys(dropdownValue).length === 0 &&
                                    Object.getPrototypeOf(dropdownValue) === Object.prototype ?
                                    null :
                                    dropdownValue
                            }
                            renderInput={(params) => <TextField
                                {...params}
                                label={get(item, ['key'], '')}
                                variant="outlined" />}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} className={classes.option}>
                                        <div>{get(props, ['primary'], '')}</div>
                                        <div className={classes.secondaryDropdownText}>{get(props, ['secondary'], '')}</div>
                                    </li>
                                )
                            }}
                        />
                    </div>
                }))
        } else {
            return currentToDisplay.map((item, index) => {
                if (get(auditConfig, ['hidden_fields'], []).includes(get(item, ['key']))) return null
                if (get(item, ['key'] === 'sr_no')) return null
                if (
                    currentToDisplayConfidenceIsHigh() &&
                    get(auditConfig, ['hidden_fields_on_low_confidence'], []).includes(get(item, ['key']))
                ) return null
                return (
                    <div key={index}>
                        <Typography variant="subtitle2" component="div" className={classes.cardTitle}>
                            {get(item, ['key'], '')}
                        </Typography>
                        <div className={classes.cardTitleContainer}>
                            <TextField
                                disabled={get(item, ['key']) === 'ppn_code' && !isAddEntryMode} // HARDCODING TO BE REMOVED
                                size='small'
                                fullWidth
                                value={get(item, ['value'], '') === null ? '' : get(item, ['value'], '')}
                                onChange={(e) => updateValue(index, e.target.value)} />
                            <ButtonGroup size="small" className={classes.nfnrBtns}>
                                <Button onClick={() => updateValue(index, 'Not Found')}>
                                    NF
                                </Button>
                                <Button onClick={() => updateValue(index, 'Not Readable')}>
                                    NR
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                )
            })
        }
    }

    if (isFetchingData) {
        return (
            <Paper className={classes.loadingAuditArea}>
                <CircularProgress className={classes.loadingIcon} />
                <Typography variant="body2">Fetching Data</Typography>
            </Paper>
        );
    }

    return (
        <Paper id="audit-area" className={classes.auditArea}>
            <div className={classes.formContainer}>
                {
                    renderAuditForm()
                }
            </div>
            <div className={classes.buttonContainer}>
                {
                    !isAddEntryMode &&
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="inherit"
                        onClick={goToPreviousLine}
                        size='small'
                        disabled={operationInProgress || currentIndex === 0}>
                        {'Previous'}
                    </Button>
                }
                {
                    !isAddEntryMode &&
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={verifyEntry}
                        size='small'
                        disabled={operationInProgress}>
                        {'Verify'}
                    </Button>
                }
                {
                    !isAddEntryMode &&
                    currentToDisplayDataType === 'tables_tabs' &&
                    <Button
                        className={clsx(classes.addButton, classes.button)}
                        variant="contained"
                        onClick={() => addEntry(false)}
                        size='small'
                        disabled={operationInProgress}>
                        {'Add Row Before'}
                    </Button>
                }
                {/* {
                    !isAddEntryMode &&
                    currentToDisplayDataType === 'tables_tabs' &&
                    <Button
                        className={clsx(classes.removeButton, classes.button)}
                        variant="contained"
                        onClick={removeEntry}
                        size='small'
                        disabled={operationInProgress}>
                        {'Remove Current Row'}
                    </Button>
                } */}
                {
                    isAddEntryMode &&
                    <Button
                        className={clsx(classes.addButton, classes.button)}
                        variant="contained"
                        onClick={addEntryToDb}
                        size='small'
                        disabled={operationInProgress}>
                        {'Add Row'}
                    </Button>
                }
                {
                    isAddEntryMode &&
                    <Button
                        className={clsx(classes.removeButton, classes.button)}
                        variant="contained"
                        onClick={cancelAddingRow}
                        size='small'
                        disabled={operationInProgress}>
                        {'Cancel'}
                    </Button>
                }
            </div>
            <div className={classes.buttonContainer}>
                {
                    !isAddEntryMode &&
                    currentToDisplayDataType === 'tables_tabs' &&
                    <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => shiftHighlight(true)}
                        size='small'
                        disabled={operationInProgress || currentIndex + highlightShiftCounter === 0}>
                        {'Shift Highlight Up'}
                    </Button>
                }
                {
                    !isAddEntryMode &&
                    currentToDisplayDataType === 'tables_tabs' &&
                    <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => shiftHighlight(false)}
                        size='small'
                        disabled={operationInProgress || currentIndex + highlightShiftCounter === auditArray.length - 1}>
                        {'Shift Highlight Down'}
                    </Button>
                }
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="inherit"
                    onClick={() => setShowIssueDialog(true)}
                    size='small'>
                    {'Raise Issue'}
                </Button>
            </div>
            <Dialog
                open={showDocumentCompletionDialog}
                onClose={handleDialogClose}
            >
                <DialogContent>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        disabled={operationInProgress}
                        onClick={() => addEntry(true)}>
                        {'Add Row At The End'}
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        disabled={operationInProgress}
                        onClick={markAuditCompleted}>
                        {'Mark Document As Complete'}
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openValidationDialog}
                onClose={() => setOpenValidationDialog(false)}
            >
                <DialogTitle>{'Entry Validation Error'}</DialogTitle>
                <DialogContent>
                    {validationError.map((error, index) => {
                        return (<DialogContentText key={`err_${index}`}>
                            {error}
                        </DialogContentText>)
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenValidationDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showIssueDialog} onClose={() => setShowIssueDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Raise Issue</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please mention the issue
                    </DialogContentText>
                    <TextField
                        error={issueError}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Issue"
                        type="email"
                        fullWidth
                        value={issueComment}
                        onChange={e => setIssueComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isRaisingIssue}
                        onClick={() => setShowIssueDialog(false)}
                        color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={isRaisingIssue}
                        onClick={raiseIssue}
                        color="primary">
                        Raise Issue
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default AuditArea