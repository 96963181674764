import { SET_PRODUCT } from "../type"

const initialState = {
    product: null
}

const productReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRODUCT:
            return { ...state, product: payload }
        default:
            return state
    }
}

export default productReducer