import { SET_USER_DETAILS } from "../type"

const initialState = {
    userEmail: null,
    uid: null,
    role: 'default'
}

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                userEmail: payload.email,
                uid: payload.uid,
                role: payload.role
            }
        default:
            return state
    }
}

export default userReducer