import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { makeStyles } from "@material-ui/styles"
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import get from 'lodash.get'

import Header from '../components/general/Header'
import JsonArea from '../components/extractedDataPage/JsonArea'
import PdfArea from '../components/extractedDataPage/PdfArea'
import LoadingPage from './LoadingPage'
import AuditArea from '../components/extractedDataPage/AuditArea'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    }
}))

const ExtractedDataPage = props => {

    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()

    const firstUpdate = useRef(true)

    const product = useSelector(state => state.product.product)
    const userEmail = useSelector(state => state.user.userEmail)
    const role = useSelector(state => state.user.role)

    const documentStatus = location.state.documentStatus

    const [isFetchingInitialDetails, setIsFetchingInitialDetails] = useState(true)
    const [isFetchingDetails, setIsFetchingDetails] = useState(false)
    const [pdf, setPdf] = useState(null)
    const [json, setJson] = useState(null)
    const [roomMapping, setRoomMapping] = useState({})
    const [auditArray, setAuditArray] = useState([])
    const [auditStartIndex, setAuditStartIndex] = useState(0)
    const [auditConfig, setAuditConfig] = useState({})
    const [selection, setSelection] = useState(null)
    const [isAuditView, setIsAuditView] = useState(false)
    const [pageDimensions, setPageDimensions] = useState([])

    const getDocumentDataInitializer = async () => {
        const documentId = location.state.documentId
        const response = await axios.get(`/${product}/documentdetails/${documentId}/${userEmail}`)
        setPdf(get(response, ['data', 's3_url']))
        setRoomMapping(get(response, ['data', 'rooms_mapping'], {}))
        setJson(get(response, ['data']))
        setIsFetchingInitialDetails(false)
    }

    const getDocumentData = async () => {
        setIsFetchingDetails(true)
        const documentId = location.state.documentId
        const response = await axios.get(`/${product}/documentdetails/${documentId}/${userEmail}`)
        setJson(get(response, ['data']))
        setAuditConfig(get(response, ['data', 'config'], {}))
        setRoomMapping(get(response, ['data', 'rooms_mapping'], {}))
        setPageDimensions(get(response, ['data', 'page_dimensions'], []))
        setIsFetchingDetails(false)
    }

    const getDocumentDataForAudit = async () => {
        setIsFetchingDetails(true)
        const documentId = location.state.documentId

        const response = await axios.get(`/${product}/documentdetailsforaudit/${documentId}/${userEmail}`)
        setAuditArray(get(response, ['data', 'data_array'], []))
        setAuditStartIndex(get(response, ['data', 'audit_start_index'], 0))
        setAuditConfig(get(response, ['data', 'config'], {}))
        setPageDimensions(get(response, ['data', 'page_dimensions'], []))
        setIsFetchingDetails(false)
    }

    useEffect(() => {
        getDocumentDataInitializer()
        if (role === 'auditor' && documentStatus !== 'complete') {
            setIsAuditView(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }
        if (isAuditView) {
            getDocumentDataForAudit()
        } else {
            getDocumentData()
        }
        // eslint-disable-next-line
    }, [isAuditView])

    if (isFetchingInitialDetails) {
        return <LoadingPage />
    }

    return (
        <div className={classes.root}>
            <Header />
            {
                !!pdf
                && <div className={classes.page}>
                    <AppBar position="static" color='inherit'>
                        <Toolbar variant='dense'>
                            <IconButton
                                size="medium"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => history.push('/', { data: location.state.data, tabFilters: location.state.tabFilters })}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="body1" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                {get(json, ['filename'], '')}
                            </Typography>
                            {
                                (
                                    !['default', 'auditor', 'ewa', 'master'].includes(role) ||
                                    (role === 'auditor' && documentStatus !== 'complete')
                                ) &&
                                <Button
                                    color="inherit"
                                    variant='outlined'
                                    size='small'
                                    onClick={() => setIsAuditView(prev => !prev)}>
                                    {
                                        isAuditView ?
                                            'Go to Overview View' :
                                            'Go to Audit View'
                                    }
                                </Button>
                            }
                            {
                                (
                                    role === 'ewa'
                                ) &&
                                <Button
                                    component='a'
                                    color="inherit"
                                    variant='outlined'
                                    size='small'
                                    href={pdf}
                                    download='a.pdf'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {
                                        `Download PDF`
                                    }
                                </Button>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className={classes.container}>
                        <PdfArea
                            pdf={pdf}
                            selection={selection}
                            isAuditView={isAuditView}
                            pageDimensions={pageDimensions} />
                        {
                            isAuditView ?
                                <AuditArea
                                    isFetchingData={isFetchingDetails}
                                    auditArray={auditArray}
                                    setAuditArray={setAuditArray}
                                    auditStartIndex={auditStartIndex}
                                    setSelection={setSelection}
                                    documentId={location.state.documentId}
                                    auditConfig={auditConfig}
                                    getDocumentDataForAudit={getDocumentDataForAudit}
                                    filename={get(json, ['filename'], '')} /> :
                                <JsonArea
                                    isFetchingData={isFetchingDetails}
                                    json={json}
                                    setSelection={setSelection}
                                    auditConfig={auditConfig}
                                    roomMapping={roomMapping}
                                    excelFileName={`${location.state.rohiniCode}_${location.state.hospitalName}`} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ExtractedDataPage