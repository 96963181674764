import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { auth } from '../config/firebase/firebase.config'
import BmtLogo from '../assets/bmt.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    branding: {
        fontFamily: 'AvantGardeRegular'
    },
    logo: {
        width: '12rem'
    }
}))

export default function LoginPage() {

    const classes = useStyles()

    const [email, setEmail] = useState('')
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false)
    const [error, setError] = useState(null)

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget

        if (name === 'userEmail') {
            setEmail(value)
        }
    }

    const sendResetEmail = event => {
        event.preventDefault()
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true)
                setTimeout(() => { setEmailHasBeenSent(false) }, 3000)
            })
            .catch((e) => {
                setError('Error resetting password')
                setTimeout(() => { setError(null) }, 3000)
            })
    }

    return (
        <Container component='main' maxWidth='xs'>
            <Paper>
                <CssBaseline />
                <div className={classes.paper}>
                    <img className={classes.logo} src={BmtLogo} alt='' />
                    e                    <Typography component='h1' variant='h5' className={classes.branding}>
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='userEmail'
                            label='Email Address'
                            name='userEmail'
                            autoComplete='email'
                            autoFocus
                            value={email}
                            onChange={(event) => onChangeHandler(event)}
                        />
                        {!!error && <Typography variant='body1' color='error'>{error}</Typography>}
                        {!!emailHasBeenSent && <Typography variant='body1' color='textSecondary'>{'An email has been sent'}</Typography>}
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={(event) => sendResetEmail(event)}
                        >
                            {'Send Reset Link'}
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link href='#' variant='body2'>
                                    <RouterLink to='/login'>
                                        {'Go back to Sign In page'}
                                    </RouterLink>
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Container>
    )
}