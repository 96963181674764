import React, { useState, useEffect } from "react"
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import axios from "axios"
import { makeStyles } from "@material-ui/styles"
import get from "lodash.get"
import clonedeep from "lodash.clonedeep"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TextField from '@material-ui/core/TextField'

import Header from "../components/general/Header"
import CustomTable from "../components/general/table/CustomTable"
import NoPdfArea from "../components/extractedDataPage/NoPdfArea"
import LoadingPage from "./LoadingPage"
import set from "lodash.set"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    page: {
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
    },
    tableWrapper: {
        margin: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        width: "1200px",
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: '0.5rem'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '0.5em'
    }
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const HomePage = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const [isUploadingPdf, setIsUploadingPdf] = useState(false)
    const [isUploadingGipsaFile, setIsUploadingGipsaFile] = useState(false)
    const [isFetchingUserData, setIsFetchingUserData] = useState(true)

    const userEmail = useSelector((state) => state.user.userEmail)
    const product = useSelector((state) => state.product.product)

    const [userDocumentsData, setUserDocumentsData] = useState([])
    const [userDocumentsHeaders, setUserDocumentsHeaders] = useState([])
    const [tabFilters, setTabFilters] = useState({})
    const [tableHeaderWidths, setTableHeadersWidth] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchText, setSearchText] = useState('')
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false)


    const appendToArrayTypeStateVariable = (
        variable,
        setVariable,
        newEntry,
        popIndex = null
    ) => {
        let temp = clonedeep(variable)
        if (!!popIndex) {
            temp.splice(popIndex, 1, newEntry)
        } else {
            temp.push(newEntry)
        }
        setVariable(temp)
    }

    const setPdfs = async (files) => {
        setIsUploadingPdf(true)
        // eslint-disable-next-line
        files.map((file) => {
            appendToArrayTypeStateVariable(
                userDocumentsData,
                setUserDocumentsData,
                userDocumentDataEntry(file.name)
            )
        })
        for (let i = 0; i < files.length; i++) {
            const row = await uploadPdf(files[i])
            setTimeout(() => {
                appendToArrayTypeStateVariable(
                    userDocumentsData,
                    setUserDocumentsData,
                    row
                )
            }, 5000)
        }
        setIsUploadingPdf(false)
    }

    const userDocumentDataEntry = (filename) => {
        const entry = {}
        // eslint-disable-next-line
        userDocumentsHeaders.map((header) => {
            if (header === "filename") entry[header] = filename
            else if (header === "status") entry[header] = "pending"
            else entry[header] = ""
        })
        return entry
    }

    const onRowClick = (rowIndex) => {
        const documentId = get(
            filteredData,
            [rowIndex, "document_id"],
            null
        )
        const documentStatus = get(
            filteredData,
            [rowIndex, "status"],
            null
        )
        const rohiniCode = get(
            filteredData,
            [rowIndex, "rohini_code"],
            null
        )
        const hospitalName = get(
            filteredData,
            [rowIndex, "name"],
            null
        )
        console.log(get(filteredData, [rowIndex]))
        if (!documentId) return
        history.push("/data", { documentId, documentStatus, tabFilters, rohiniCode, hospitalName })
    }

    const uploadPdf = async (pdf) => {
        try {
            const fd = new FormData()
            fd.append("file", pdf)
            fd.append("email", userEmail)
            const response = await axios.post(`/${product}/upload`, fd)
            return get(response, ["data"], {})
        } catch (e) {
            console.error(e)
        }
    }

    const getDocuments = async (tempTabFilters = null) => {

        const response = await axios.get(
            `/${product}/userdocuments/${userEmail}`
        )
        setUserDocumentsData(get(response, ["data", "table_data"], null))
        setUserDocumentsHeaders(get(response, ["data", "table_headers"], null))
        setTableHeadersWidth(get(response, ["data", "table_headers_width"], null))
        if (tempTabFilters !== null) {
            setTabFilters(tempTabFilters)
        } else {
            setTabFilters(get(response, ["data", "filters"], {}))
        }
    }

    const selectFilter = (value, filterName) => {
        const tempTabFilter = clonedeep(tabFilters)
        set(tempTabFilter, [filterName, 'selectedValue'], value)
        setTabFilters(tempTabFilter)
    }

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessSnackbar(false)
    }

    const handleFailureSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenFailureSnackbar(false)
    }

    useEffect(() => {
        const tempTabFilters = get(location, ['state', 'tabFilters'], null)
        getDocuments(tempTabFilters)
        setIsFetchingUserData(false)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Object.keys(tabFilters).length === 0 && searchText.trim().length === 0) {
            setFilteredData(clonedeep(userDocumentsData))
            return
        }
        let tempFilteredData = clonedeep(userDocumentsData)
        if (Object.keys(tabFilters).length > 0) {
            // eslint-disable-next-line
            Object.keys(tabFilters).map(filter => {
                if (get(tabFilters, [filter, 'selectedValue'], null) !== null) {
                    tempFilteredData = tempFilteredData.filter((item) => get(item, [filter]) === get(tabFilters, [filter, 'selectedValue'], null))
                }
            })

        }
        if (searchText.trim().length > 0) {
            tempFilteredData = tempFilteredData.filter((item) => {
                let selectRow = false
                userDocumentsHeaders.forEach((header, index) => {
                    if (get(item, [header], '').toLowerCase().includes(searchText.toLowerCase())) {
                        selectRow = true
                    }
                })
                return selectRow
            })
        }
        setFilteredData(tempFilteredData)
        // eslint-disable-next-line
    }, [tabFilters, userDocumentsData, searchText])

    if (isFetchingUserData || isUploadingGipsaFile) {
        return <LoadingPage />
    }

    if (userDocumentsData.length === 0) {
        return (
            <div className={classes.root}>
                <Header
                    showUpload
                    setPdfs={setPdfs}
                    isUploadingPdf={isUploadingPdf}
                />
                <NoPdfArea setPdfs={setPdfs} isUploadingPdf={isUploadingPdf} />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Header
                showUpload
                setPdfs={setPdfs}
                isUploadingPdf={isUploadingPdf}
            />
            <div className={classes.tableWrapper}>
                <div className={classes.filterContainer}>
                    {
                        Object.keys(tabFilters).length > 0 &&
                        <div className={classes.tabContainer}>
                            {Object.keys(tabFilters).map((filter, i) => {
                                return (
                                    <ToggleButtonGroup
                                        style={{ marginBottom: '0.5rem' }}
                                        key={i}
                                        value={get(tabFilters, [filter, 'selectedValue'], null)}
                                        exclusive
                                        size="small"
                                        onChange={(event, newValue) => selectFilter(newValue, filter)}
                                        aria-label="text alignment"
                                    >
                                        {
                                            get(tabFilters, [filter, 'values']).map((item, index) => (
                                                <ToggleButton key={index} value={item}>
                                                    {item}
                                                </ToggleButton>
                                            ))
                                        }
                                    </ToggleButtonGroup>
                                )
                            })}
                        </div>
                    }
                    <div>
                        <TextField
                            label="Search"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)} />
                    </div>
                </div>
                <CustomTable
                    tableData={filteredData}
                    tableHeaders={userDocumentsHeaders}
                    onRowClick={onRowClick}
                    hiddenColumns={[]}
                    roomMapping={{}}
                    searchText={searchText}
                    tableHeaderWidths={tableHeaderWidths}
                    pagination
                    showUpload
                    setIsUploadingGipsaFile={setIsUploadingGipsaFile}
                    getDocuments={getDocuments}
                    setOpenSuccessSnackbar={setOpenSuccessSnackbar}
                    setOpenFailureSnackbar={setOpenFailureSnackbar}
                />
            </div>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} severity="success">
                    Upload Successful
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000} onClose={handleFailureSnackbarClose}>
                <Alert onClose={handleFailureSnackbarClose} severity="error">
                    Upload Failed
                </Alert>
            </Snackbar>
        </div >
    )
}

export default HomePage
