import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/styles"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    noPdfArea: {
        height: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const NoPdfArea = props => {

    const classes = useStyles()

    const { setPdfs, isUploadingPdf } = props

    const role = useSelector(state => state.user.role)

    const { getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: 'application/pdf',
        maxFiles: 100
    })

    const getDisplayTextByRole = () => {
        if (role === 'auditor') {
            return 'No Document available to Audit. Check back later!'
        } else if (['default', 'admin'].includes(role)) {
            return 'Upload PDF to Proceed!'
        } else {
            return 'Contact team bookmytreatmet for access'
        }
    }

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setPdfs(acceptedFiles)
        }
        // eslint-disable-next-line
    }, [acceptedFiles])

    return (
        <div className={classes.noPdfArea}>
            <Typography variant="h6" gutterBottom color='textSecondary'>
                {getDisplayTextByRole()}
            </Typography>
            <input {...getInputProps()} hidden />
            {['default', 'admin'].includes(role)
                && <Button disabled={isUploadingPdf} variant="outlined" onClick={open}>
                    {'Upload'}
                </Button>}
        </div>
    )

}

export default NoPdfArea