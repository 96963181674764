import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ExtractedDataPage from "./pages/ExtractedDataPage"
import HomePage from "./pages/HomePage"
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import LoadingPage from './pages/LoadingPage'
import ResendPasswordPage from './pages/ResendPasswordPage'
import PreAuthConfigPage from './pages/PreAuthConfigPage'

const RoutingPage = (props) => {

    const { isSignedIn, isAuthChecked } = props

    const isConfigSet = useSelector(state => state.auth.isConfigSet)
    const loginError = useSelector(state => state.auth.loginError)

    let routes

    if (!isConfigSet) {
        routes = (
            <Switch>
                <Route path='/' exact component={PreAuthConfigPage} />
                <Redirect to='/' />
            </Switch>
        )
    }

    if (!isAuthChecked && isConfigSet) {
        routes = (
            <Switch>
                <Route path='/' exact component={LoadingPage} />
                <Redirect to='/' />
            </Switch>
        )
    }

    if (!isSignedIn && isAuthChecked) {
        routes = (
            <Switch>
                <Route path='/login' exact component={LoginPage} />
                <Route path='/signup' exact component={SignupPage} />
                <Route path='/reset' exact component={ResendPasswordPage} />
                <Redirect to='/login' />
            </Switch>
        )
    }

    if (loginError.length > 0) {
        routes = (
            <Switch>
                <Route path='/login' exact component={LoginPage} />
                <Route path='/signup' exact component={SignupPage} />
                <Route path='/reset' exact component={ResendPasswordPage} />
                <Redirect to='/login' />
            </Switch>
        )
    }

    if (isSignedIn) {
        routes = (
            <Switch>
                <Route path="/data" exact component={ExtractedDataPage} />
                <Route path="/" exact component={HomePage} />
                <Redirect to='/' />
            </Switch>
        )
    }

    return <Router>{routes}</Router>;
};

export default RoutingPage;