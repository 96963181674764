import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import userReducer from './reducers/user'
import authReducer from './reducers/auth'
import dataReducer from './reducers/data'
import errorReducer from './reducers/error'
import productReducer from './reducers/product'

const rootReducer = combineReducers({
    user: userReducer,
    auth: authReducer,
    data: dataReducer,
    error: errorReducer,
    product: productReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))