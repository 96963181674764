import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

var firebaseConfig = {
    apiKey: "AIzaSyCs8RheqVtjuwYXu8hW7VqsCTttMp9Ke3Y",
    authDomain: "instic-ai-banking.firebaseapp.com",
    projectId: "instic-ai-banking",
    storageBucket: "instic-ai-banking.appspot.com",
    messagingSenderId: "350478943278",
    appId: "1:350478943278:web:8a8c99df2abb8428508bbf"
}

firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()