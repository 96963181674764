import { AUTH_CHECKED, SET_IS_CONFIG_SET, SET_IS_EMAIL_VERIFIED, SIGNOUT, SIGNIN, SET_LOGIN_ERROR, VERIFY_EMAIL_AND_AUTH } from "../type"

const initialState = {
    isAuthChecked: false,
    isSignedIn: false,
    isEmailVerified: false,
    isConfigSet: false,
    loginError: ''
}

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SIGNIN:
            return {
                ...state,
                isSignedIn: true,
            }
        case VERIFY_EMAIL_AND_AUTH:
            return {
                ...state,
                isAuthChecked: true,
                isEmailVerified: true,
            }
        case AUTH_CHECKED:
            return { ...state, isAuthChecked: true }
        case SET_IS_CONFIG_SET:
            return { ...state, isConfigSet: payload.isConfigSet }
        case SIGNOUT:
            return { ...initialState, isAuthChecked: true }
        case SET_IS_EMAIL_VERIFIED:
            return { ...state, isEmailVerified: payload.isEmailVerified }
        case SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: payload.error,
            }
        default:
            return state
    }
}

export default authReducer