import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'

import { auth } from '../../config/firebase/firebase.config'
import bmtLogo from "../../assets/bmt.png"

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontFamily: "AvantGardeRegular",
        letterSpacing: "5px",
        fontSize: "1.5rem",
        fontWeight: "600",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    logo: {
        width: "10rem",
        height: "2.5rem",
        paddingBottom: "0.3rem",
        paddingRight: "0.5rem",
    },
}))

const Header = props => {

    const { setPdfs, showUpload, isUploadingPdf } = props

    const classes = useStyles()

    const role = useSelector(state => state.user.role)

    const signout = () => {
        auth.signOut()
    }

    const { getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: 'application/pdf',
        maxFiles: 100
    })

    useEffect(() => {
        if (acceptedFiles.length >= 1) {
            setPdfs(acceptedFiles)
        }
        // eslint-disable-next-line
    }, [acceptedFiles])

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    <img src={bmtLogo} alt="" className={classes.logo} />
                    {""}
                </Typography>
                <input {...getInputProps()} hidden />
                {
                    showUpload &&
                    ['default', 'admin'].includes(role) &&
                    <Button
                        color="inherit"
                        onClick={open}
                        disabled={isUploadingPdf}
                    >
                        {'Upload'}
                    </Button>
                }
                <Button
                    color="inherit"
                    onClick={signout}
                >
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header