import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const LoadingPage = () => {

    const classes = useStyles()

    return (
        <div className={classes.loadingContainer}>
            <CircularProgress />
        </div>
    )
}

export default LoadingPage