import axios from 'axios'
import get from 'lodash.get'

import { AUTH_CHECKED, SET_IS_EMAIL_VERIFIED, SIGNIN, SIGNOUT, SET_IS_CONFIG_SET, SET_LOGIN_ERROR, VERIFY_EMAIL_AND_AUTH } from "../type"
import * as UserActions from './user'

export const signin = (email, uid) => {
    return async (dispatch, getState) => {
        dispatch({
            type: VERIFY_EMAIL_AND_AUTH
        })
        dispatch({
            type: SET_LOGIN_ERROR,
            payload: {
                error: ''
            }
        })
        const product = getState().product.product
        try {
            const userDetailsResponse = await axios.get(`/${product}/userdetails/${email}`)
            dispatch(
                UserActions.setUserDetails(
                    email,
                    uid,
                    get(userDetailsResponse, ['data', 'role'], 'unknown'
                    )
                )
            )
            dispatch({
                type: SIGNIN
            })
        } catch (e) {
            dispatch({
                type: SET_LOGIN_ERROR,
                payload: {
                    error: 'Login Invalid! Please contact team bookmytreatment.'
                }
            })
        }
    }
}

export const setIsConfigSet = (isConfigSet) => ({
    type: SET_IS_CONFIG_SET,
    payload: {
        isConfigSet
    }
})

export const setAuthChecked = () => ({
    type: AUTH_CHECKED
})

export const setIsEmailVerified = (isEmailVerified) => ({
    type: SET_IS_EMAIL_VERIFIED,
    payload: {
        isEmailVerified
    }
})

export const signout = () => ({
    type: SIGNOUT
})